import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, FlatList, ScrollView, Dimensions } from 'react-native';
import Hoverable from 'react-native-hoverable';
import Seat from './Seat';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useUser } from './UserContext';

const SeatArrangement = ({ levels, onSeatClick, selectedSeats, onCancel, onBook, useRewardPoints = false }) => {
    const [showTooltip, setShowTooltip] = useState(null);
    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const { user, userPoints } = useUser();

    const getSeatType = (seat) => {
        switch (seat.seatType) {
            case 'REGULAR': return 'Regular Seat';
            case 'SINGLE_REGULAR': return 'Single Seat';
            case 'PUSHBACK': return 'Pushback';
            case 'SEMI_SLEEPER': return 'Semi Sleeper';
            case 'SLEEPER': return 'Sleeper';
            case 'SINGLE_LOWER_SLEEPER': return 'Single Lower';
            case 'SINGLE_UPPER_SLEEPER': return 'Single Upper';
            case 'LOWER_SLEEPER': return 'Lower Sleeper';
            case 'UPPER_SLEEPER': return 'Upper Sleeper';
            default: return 'Seat';
        }
    };

    const isSleeper = (seat) => {
        switch (seat.seatType) {        
            case 'SLEEPER': return true;
            case 'SINGLE_LOWER_SLEEPER': return true;
            case 'SINGLE_UPPER_SLEEPER': return true;
            case 'LOWER_SLEEPER': return true;
            case 'UPPER_SLEEPER': return true;
            default: return false;
        }
    };

    const calculateEstimatedTotalCost = () => {
        if (!selectedSeats || selectedSeats.length === 0) return {
            basePrice: 0,
            discountAmount: 0,
            totalTaxes: 0,
            totalCost: 0,
            pointsApplied: 0,
            finalAmount: 0
        };
    
        const initialCosts = selectedSeats.reduce((acc, seat) => {
            const basePrice = seat.seatRate.baseFare || 0;
            const discountedPrice = seat.seatRate.discountedBaseFare || basePrice;
            const discount = seat.seatRate.discount || 0;
            const taxes = seat.seatRate.totalTax || 0;
    
            return {
                basePrice: acc.basePrice + basePrice,
                discountAmount: acc.discountAmount + discount,
                totalTaxes: acc.totalTaxes + taxes,
                totalCost: acc.totalCost + (seat.seatRate.totalCost || 0)
            };
        }, {
            basePrice: 0,
            discountAmount: 0,
            totalTaxes: 0,
            totalCost: 0
        });
    
        // Apply reward points if enabled
        if (useRewardPoints && userPoints > 0) {
            const totalCostPaise = initialCosts.totalCost * 100; // Convert to paise
            const pointsToApply = Math.min(userPoints, totalCostPaise); // Use points up to total cost in paise
            const pointsValueInRupees = pointsToApply / 100; // Convert back to rupees
    
            return {
                ...initialCosts,
                pointsApplied: pointsToApply,
                pointsValue: pointsValueInRupees,
                finalAmount: Math.max(0, initialCosts.totalCost - pointsValueInRupees)
            };
        }
    
        return {
            ...initialCosts,
            pointsApplied: 0,
            pointsValue: 0,
            finalAmount: initialCosts.totalCost
        };
    };
    
    const renderSeats = (level) => {
        try {
            // Initialize grid with correct dimensions
            const seatGrid = [];
            for (let i = 0; i < level.maxRows; i++) {
                seatGrid[i] = new Array(level.maxColumns).fill(null);
            }
    
            // First pass: Map seats to their exact positions
            level.seats.forEach(seat => {
                if (seat.row > 0 && seat.col > 0 && 
                    seat.row <= level.maxRows && 
                    seat.col <= level.maxColumns) {
                    const row = seat.row - 1;
                    const col = seat.col - 1;
                    seatGrid[row][col] = {
                        ...seat,
                        isWide: isSleeper(seat)
                    };
                }
            });
    
            // Helper function to find last occupied column in a row
            const findLastOccupiedColumnInRow = (row) => {
                let lastCol = -1;
                row.forEach((seat, col) => {
                    if (seat !== null) {
                        lastCol = col;
                    }
                });
                return lastCol;
            };
    
            // Helper function to check if row is empty
            const isEmptyRow = (row) => {
                return row.every(seat => seat === null || seat.seatType === 'FREESPACE' ||  seat.seatType === 'WIDER_FREESPACE');
            };
    
            return (
                <View style={styles.levelSeatsContainer}>
                    {seatGrid.map((row, rowIndex) => {
                        // Check if the row is empty
                        if (isEmptyRow(row)) {
                            return (
                                <View 
                                    key={`empty-row-${rowIndex}`} 
                                    style={[styles.emptyRow]}
                                >
                                    <View style={styles.emptyRowLine} />
                                </View>
                            );
                        }
    
                        return (
                            <View key={rowIndex} style={styles.seatRow}>
                                {row.map((seat, colIndex) => {
                                    const lastSeatIndex = findLastOccupiedColumnInRow(row);
    
                                    if(lastSeatIndex !== -1 && colIndex > lastSeatIndex) {
                                        return null;
                                    }
    
                                    if (!seat) {
                                        return (
                                            <View 
                                                key={`empty-${rowIndex}-${colIndex}`} 
                                                //style={styles.emptySeat} 
                                            />
                                        );
                                    } else if (seat.seatType === 'FREESPACE') {
                                        return (
                                            <View 
                                                key={`empty-${rowIndex}-${colIndex}`} 
                                                style={styles.emptySeat} 
                                            />
                                        );
                                    } else if (seat.seatType === 'WIDER_FREESPACE') {
                                        return (
                                            <View 
                                                key={`empty-${rowIndex}-${colIndex}`} 
                                                style={styles.widerEmptySeat} 
                                            />
                                        );
                                    }
    
                                    return (
                                        <View 
                                            key={`seat-${rowIndex}-${colIndex}`} 
                                            style={[
                                                styles.seatWrapper,
                                                seat.isWide && styles.wideSeatWrapper
                                            ]}
                                        >
                                            <Hoverable
                                                onHoverIn={() => setShowTooltip(seat.seatNo)}
                                                onHoverOut={() => setShowTooltip(null)}
                                            >
                                                <View>
                                                    <Seat
                                                        seat={seat}
                                                        onSeatClick={onSeatClick}
                                                        isSelected={selectedSeats?.some(
                                                            s => s.seatNo === seat.seatNo
                                                        )}
                                                    />
                                                    {showTooltip === seat.seatNo && (
                                                        <View style={styles.tooltipContainer}>
                                                            <Text style={styles.tooltipText}>
                                                                {getSeatType(seat)}
                                                                {'\n'}₹{seat.seatRate.baseFare}
                                                                {seat.seatRate.discountedBaseFare < 
                                                                    seat.seatRate.baseFare &&
                                                                    ` → ₹${Math.floor(seat.seatRate.discountedBaseFare)}`
                                                                }
                                                            </Text>
                                                        </View>
                                                    )}
                                                </View>
                                            </Hoverable>
                                        </View>
                                    );
                                })}
                            </View>
                        );
                    })}
                </View>
            );
        } catch (error) {
            console.error('Error rendering seats:', error);
            return (
                <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>Error loading seat layout</Text>
                </View>
            );
        }
    };

    const renderLegend = () => (
        <View style={styles.legendContainer}>
            <View style={styles.legendRow}>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.availableSample]} />
                    <Text style={styles.legendText}>Available</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.maleSample]} />
                    <Text style={styles.legendText}>Available Male Only</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.femaleSample]} />
                    <Text style={styles.legendText}>Available Female Only</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.selectedSample]} />
                    <Text style={styles.legendText}>Selected</Text>
                </View>
            </View>
            <View style={styles.legendRow}>
            <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.unavailableSample]} />
                    <Text style={styles.legendText}>Booked</Text>
                </View>

                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.maleOccupiedSample]} />
                    <Text style={styles.legendText}>Booked Male</Text>
                </View>

                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.femaleOccupiedSample]} />
                    <Text style={styles.legendText}>Booked Female</Text>
                </View>
            </View>
        </View>
    );

    const renderGenderRestrictions = () => {
        const restrictedSeats = selectedSeats?.filter(seat => 
            seat.seatStatus === 'AVAILABLE_MALE' || seat.seatStatus === 'AVAILABLE_FEMALE'
        );

        if (!restrictedSeats?.length) return null;

        return (
            <View style={styles.restrictionsContainer}>
                <View style={styles.restrictionsHeader}>
                    <Ionicons name="warning" size={20} color="#f44336" />
                    <Text style={styles.restrictionsTitle}>Gender Restrictions Apply</Text>
                </View>
                {restrictedSeats.map(seat => (
                    <Text key={seat.seatNo} style={styles.restrictionText}>
                        Seat {seat.seatNo} is only available for 
                        {seat.seatStatus === 'AVAILABLE_MALE' ? ' male' : ' female'} passengers
                    </Text>
                ))}
            </View>
        );
    };

    const renderSeatSummary = () => {
        if (!selectedSeats || selectedSeats.length === 0) return null;

        const costs = calculateEstimatedTotalCost();
        const hasDiscount = costs.discountAmount > 0;
        const hasPointsDiscount = useRewardPoints && costs.pointsApplied > 0;

        return (
            <View style={styles.summaryContainer}>
                <Text style={styles.summaryTitle}>Selected Seats</Text>
                <FlatList
                    data={selectedSeats}
                    keyExtractor={item => item.seatNo}
                    renderItem={({ item }) => (
                        <View style={styles.seatSummaryItem}>
                            <View style={styles.seatSummaryHeader}>
                                <Text style={styles.seatSummaryTitle}>
                                    Seat {item.seatNo} - {getSeatType(item)}
                                </Text>
                            </View>
                            <View style={styles.seatSummaryPricing}>
                                <Text style={styles.seatSummaryText}>
                                    Base Fare: ₹{item.seatRate.baseFare}
                                </Text>
                                {item.seatRate.discountedBaseFare < item.seatRate.baseFare && (
                                    <Text style={styles.discountText}>
                                        Discount: -₹{item.seatRate.discount}
                                    </Text>
                                )}
                                <Text style={styles.seatSummaryText}>
                                    Taxes: ₹{item.seatRate.totalTax}
                                </Text>
                                <Text style={styles.totalText}>
                                    Total: ₹{item.seatRate.totalCost}
                                </Text>
                            </View>
                        </View>
                    )}
                />
                <View style={styles.totalSummaryContainer}>
                    <Text style={styles.totalSummaryText}>
                        Base Price: ₹{costs.basePrice}
                    </Text>
                    {hasDiscount && (
                        <Text style={styles.discountSummaryText}>
                            Discount: -₹{costs.discountAmount}
                        </Text>
                    )}
                    <Text style={styles.totalSummaryText}>
                        Taxes: ₹{costs.totalTaxes}
                    </Text>
                    <Text style={styles.totalSummaryText}>
                        Subtotal: ₹{costs.totalCost}
                    </Text>
                    
                    {hasPointsDiscount && (
                        <>
                            <Text style={styles.pointsDiscountText}>
                                Reward Points Applied: {costs.pointsApplied} points
                            </Text>
                            <Text style={styles.pointsDiscountText}>
                                Points Value: -₹{Math.floor(costs.pointsValue)}
                            </Text>
                        </>
                    )}
                    
                    <Text style={styles.grandTotalText}>
                        Final Amount: ₹{Math.floor(costs.finalAmount)}
                    </Text>
                    
                    {useRewardPoints && (
                        <Text style={styles.pointsBalanceText}>
                            Available Points Balance: {userPoints}
                        </Text>
                    )}
                </View>
            </View>
        );
    };

    return (
        <View style={styles.container}>
            <ScrollView contentContainerStyle={styles.scrollContainer}>
                <View style={[
                    styles.mainContent,
                    { flexDirection: windowWidth < 768 ? 'column' : 'row' }
                ]}>
                    <ScrollView 
                        horizontal 
                        showsHorizontalScrollIndicator={true}
                        style={[
                            styles.busLayoutScroll,
                            { maxWidth: windowWidth < 768 ? '100%' : '65%' },
                            {overflowY: 'auto'}
                        ]}
                        contentContainerStyle={styles.busLayoutScrollContent}
                    >
                        <View style={styles.busLayout}>
                            {levels.map((level, index) => (
                                <View key={index} style={styles.levelContainer}>
                                    <View style={styles.levelHeader}>
                                        <MaterialCommunityIcons 
                                            name={level.upperLowerBith === 'Lower' ? 
                                                "steering" : "bed"}
                                            size={20} 
                                            color="#666"
                                        />
                                        <Text style={styles.levelTitle}>
                                            {level.upperLowerBith} Deck
                                        </Text>
                                    </View>
                                    {renderSeats(level)}
                                </View>
                            ))}
                            {renderLegend()}
                        </View>
                    </ScrollView>
                    <ScrollView 
                        style={[
                            styles.rightPanelScroll,
                            { maxWidth: windowWidth < 768 ? '100%' : '35%' }
                        ]}
                        showsVerticalScrollIndicator={true}
                    >
                        <View style={styles.rightPanel}>
                            {renderGenderRestrictions()}
                            {renderSeatSummary()}
                            <View style={styles.actionButtons}>
                                <TouchableOpacity 
                                    style={[styles.button, styles.cancelButton]} 
                                    onPress={onCancel}
                                >
                                    <Text style={styles.buttonText}>Cancel</Text>
                                </TouchableOpacity>
                                {selectedSeats && selectedSeats.length > 0 && (
                                    <TouchableOpacity 
                                        style={[styles.button, styles.bookButton]} 
                                        onPress={onBook}
                                    >
                                        <Text style={styles.buttonText}>Proceed</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        height: '100%',
    },
    scrollContainer: {
        flexGrow: 1,
    },
    mainContent: {
        flex: 1,
        padding: 16,
        gap: 16,
        minHeight: '100%',
    },
    busLayoutScroll: {
        flex: 1,
    },
    busLayoutScrollContent: {
        flexGrow: 1,
    },
    busLayout: {
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 16,
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        minWidth: 320,
    },
    rightPanelScroll: {
        flex: 1,
    },
    rightPanel: {
        minWidth: 300,
        maxWidth: 400,
        flex: 1,
    },
    levelContainer: {
        marginBottom: 24,
    },
    levelHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 12,
        gap: 8,
    },
    levelTitle: {
        fontSize: 14,
        fontWeight: '600',
        color: '#333',
    },
    levelSeatsContainer: {
        padding: 8,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
        //alignItems: 'center',
        width: '100%',
        minWidth: 280,
    },
    seatRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start', // Add this
        marginBottom: 2,
        flexWrap: 'nowrap',
        minHeight: 44,
    },
    seatWrapper: {
        margin: 2,
        width: 44,
    },
    wideSeatWrapper: {
        width: 92,
    },
    emptySeat: {
        width: 44,
        height: 40,
        margin: 2,
    },
    widerEmptySeat: {
        width: 92,
        height: 40,
        margin: 2,
    },
    legendContainer: {
        marginTop: 16,
        padding: 12,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
    },
    legendRow: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: 8,
    },
    legendItem: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    legendSample: {
        width: 20,
        height: 20,
        borderRadius: 4,
        borderWidth: 1,
    },
    legendText: {
        fontSize: 12,
        color: '#666',
    },
    availableSample: {
        backgroundColor: '#fff',
        borderColor: '#666',
    },
    selectedSample: {
        backgroundColor: '#4CAF50',
        borderColor: '#388E3C',
    },
    unavailableSample: {
        backgroundColor: '#e0e0e0',
        borderColor: '#bdbdbd',
    },
    maleSample: {
        backgroundColor: '#fff',
        borderColor: '#2196F3',
        borderWidth: 2,
    },
    femaleSample: {
        backgroundColor: '#fff',
        borderColor: '#E91E63',
        borderWidth: 2,
    },
    maleOccupiedSample: {
        backgroundColor: '#2196F3',
        borderColor: '#2196F3',
        borderWidth: 2,
    },
    femaleOccupiedSample: {
        backgroundColor: '#E91E63',
        borderColor: '#E91E63',
        borderWidth: 2,
    },
    tooltipContainer: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.8)',
        padding: 8,
        borderRadius: 4,
        top: -40,
        left: 0,
        zIndex: 1000,
    },
    tooltipText: {
        color: '#fff',
        fontSize: 12,
    },
    restrictionsContainer: {
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 16,
        marginBottom: 16,
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
    },
    restrictionsHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
        gap: 8,
    },
    restrictionsTitle: {
        fontSize: 16,
        fontWeight: '600',
        color: '#f44336',
    },
    restrictionText: {
        fontSize: 14,
        color: '#666',
        marginTop: 4,
    },
    summaryContainer: {
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 16,
        marginBottom: 16,
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
    },
    summaryTitle: {
        fontSize: 18,
        fontWeight: '600',
        color: '#333',
        marginBottom: 16,
    },
    seatSummaryItem: {
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        paddingVertical: 12,
    },
    seatSummaryHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    seatSummaryTitle: {
        fontSize: 14,
        fontWeight: '600',
        color: '#333',
    },
    seatSummaryPricing: {
        marginLeft: 16,
    },
    seatSummaryText: {
        fontSize: 13,
        color: '#666',
        marginBottom: 4,
    },
    discountText: {
        fontSize: 13,
        color: '#4CAF50',
        marginBottom: 4,
    },
    totalText: {
        fontSize: 14,
        fontWeight: '500',
        color: '#333',
        marginTop: 4,
    },
    totalSummaryContainer: {
        marginTop: 16,
        paddingTop: 16,
        borderTopWidth: 1,
        borderTopColor: '#eee',
    },
    totalSummaryText: {
        fontSize: 14,
        color: '#666',
        marginBottom: 4,
    },
    discountSummaryText: {
        fontSize: 14,
        color: '#4CAF50',
        marginBottom: 4,
    },
    pointsDiscountText: {
        fontSize: 14,
        color: '#2196F3',
        marginBottom: 4,
    },
    pointsBalanceText: {
        fontSize: 12,
        color: '#666',
        marginTop: 8,
        fontStyle: 'italic',
    },
    grandTotalText: {
        fontSize: 16,
        fontWeight: '600',
        color: '#333',
        marginTop: 8,
    },
    actionButtons: {
        flexDirection: 'row',
        gap: 12,
        justifyContent: 'flex-end',
        paddingVertical: 16,
    },
    button: {
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        minWidth: 100,
        alignItems: 'center',
    },
    cancelButton: {
        backgroundColor: '#f44336',
    },
    bookButton: {
        backgroundColor: '#4CAF50',
    },
    buttonText: {
        color: '#fff',
        fontSize: 14,
        fontWeight: '600',
    },
    errorContainer: {
        padding: 16,
        alignItems: 'center',
    },
    errorText: {
        color: '#f44336',
        fontSize: 14,
    },
    emptyRow: {
      
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 8,
    },
    emptyRowLine: {
        width: '90%',
        height: 1,
        backgroundColor: '#E5E7EB',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: '#D1D5DB',
    }
});

export default SeatArrangement;