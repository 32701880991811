import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal';
import ShippingAndDeliveryPolicyModal from '../modals/ShippingAndDeliveryPolicyModal';
import TermsAndConditionsModal from '../modals/TermsAndConditionsModal';
import ContactUSModal from '../modals/ContactUsModal';
import CancellationRefundPolicyModal from '../modals/CancellationRefundPolicyModal';
import AboutUsModal from '../modals/AboutUsModal';

const Footer = () => {
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [shippingDeliveryModalVisible, setShippingDeliveryModalVisible] = useState(false);
  const [contactUSModalVisible, setContactUSModalVisible] = useState(false);
  const [CancellationRefundPolicyModalVisible, setCancellationRefundPolicyModalModal] = useState(false);
  const [aboutUsModalVisible, setAboutUsModalVisible] = useState(false);

  const socialLinks = {
    linkedin: 'https://www.linkedin.com/company/your-company',
    instagram: 'https://www.instagram.com/bygbus_india/',
    facebook: 'https://www.facebook.com/profile.php?id=61564287095690',
  };

  const openSocialLink = (url) => {
    Linking.openURL(url);
  };

  // Modal functions
  const openTermsModal = () => setTermsModalVisible(true);
  const openPrivacyModal = () => setPrivacyModalVisible(true);
  const openShippingDeliveryModal = () => setShippingDeliveryModalVisible(true);
  const openContactUSModal = () => setContactUSModalVisible(true);
  const openCancellationRefundPolicyModal = () => setCancellationRefundPolicyModalModal(true);
  const openAboutUsModal = () => setAboutUsModalVisible(true);

  const closeTermsModal = () => setTermsModalVisible(false);
  const closePrivacyModal = () => setPrivacyModalVisible(false);
  const closeShippingDeliveryModal = () => setShippingDeliveryModalVisible(false);
  const closeContactUSModal = () => setContactUSModalVisible(false);
  const closeCancellationRefundPolicyModal = () => setCancellationRefundPolicyModalModal(false);
  const closeAboutUsModal = () => setAboutUsModalVisible(false);

  return (
    <View style={styles.container}>
      <View style={styles.footerContent}>
        <View style={styles.linksGroup}>
          <TouchableOpacity onPress={openTermsModal}>
            <Text style={styles.link}>Terms</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>•</Text>
          <TouchableOpacity onPress={openPrivacyModal}>
            <Text style={styles.link}>Privacy</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>•</Text>
          <TouchableOpacity onPress={openShippingDeliveryModal}>
            <Text style={styles.link}>Shipping</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>•</Text>
          <TouchableOpacity onPress={openContactUSModal}>
            <Text style={styles.link}>Contact</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>•</Text>
          <TouchableOpacity onPress={openCancellationRefundPolicyModal}>
            <Text style={styles.link}>Refund</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>•</Text>
          <TouchableOpacity onPress={openAboutUsModal}>
            <Text style={styles.link}>About</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.socialLinks}>
          <TouchableOpacity 
            onPress={() => openSocialLink(socialLinks.linkedin)}
            style={styles.iconContainer}
          >
            <FontAwesome name="linkedin-square" size={20} color="#0077B5" />
          </TouchableOpacity>
          <TouchableOpacity 
            onPress={() => openSocialLink(socialLinks.instagram)}
            style={styles.iconContainer}
          >
            <FontAwesome name="instagram" size={20} color="#E1306C" />
          </TouchableOpacity>
          <TouchableOpacity 
            onPress={() => openSocialLink(socialLinks.facebook)}
            style={styles.iconContainer}
          >
            <FontAwesome name="facebook-square" size={20} color="#1877F2" />
          </TouchableOpacity>
        </View>
      </View>

      {/* Modals */}
      <TermsAndConditionsModal isVisible={termsModalVisible} closeModal={closeTermsModal} />
      <PrivacyPolicyModal isVisible={privacyModalVisible} closeModal={closePrivacyModal} />
      <CancellationRefundPolicyModal isVisible={CancellationRefundPolicyModalVisible} closeModal={closeCancellationRefundPolicyModal}/>
      <ContactUSModal isVisible={contactUSModalVisible} closeModal={closeContactUSModal}/>
      <ShippingAndDeliveryPolicyModal isVisible={shippingDeliveryModalVisible} closeModal={closeShippingDeliveryModal}/>
      <AboutUsModal isVisible={aboutUsModalVisible} closeModal={closeAboutUsModal} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopColor: '#f0f0f0',
    borderTopWidth: 1,
    paddingVertical: 12,
  },
  footerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  linksGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  link: {
    color: 'black',
    fontSize: 12,
  },
  separator: {
    marginHorizontal: 5,
    color: '#888',
    fontSize: 8,
  },
  socialLinks: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    marginLeft: 15,
  },
});

export default Footer;