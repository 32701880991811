import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, Alert } from 'react-native';
import axios from 'axios';
import * as Print from 'expo-print';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BusBookingConfirmation = ({ bookSummary, handleViewReservation, onHome }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [reservationDetails, setReservationDetails] = useState(null);

    useEffect(() => {
        fetchReservationDetails();
    }, []);

    const handleSendEmail = async () => {
        if (!email.trim()) {
            Alert.alert('Email is required', 'Please enter an email address.');
            return;
        }

        const requestBody = {
            pnr: bookSummary.confirmationCode,
            emails : email,
            notificationType : 'CONFIRMATION'
        }

        try {
            const response = await axios.post(API_URL + '/notify', requestBody , {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });
            setMessage(response.data);
            Alert.alert('Success', 'Email sent successfully!');
        } catch (error) {
            console.log(error)
            setError('Failed to send Email. Please try again or contact BYGBUS Customer Support.')
        }
    };

    const fetchReservationDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/${bookSummary.confirmationCode}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            setReservationDetails(response.data);
        } catch (error) {
            console.error('Error fetching reservation:', error);
            Alert.alert('Error', 'Failed to fetch reservation details. Please try again.');
        }
    };

    const handlePrint = async () => {
        try {
            const response = await axios.get(API_URL + '/bus-reservation/print/' + bookSummary.confirmationCode , {
                headers: {
                    'x-api-token': API_TOKEN
                }});
            await Print.printAsync({
                html: response.data
            });
        } catch (error) {
            console.error(error);
            Alert.alert('Error', 'Failed to generate print version. Please try again.');
        }
    };

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${day}/${month}/${year} ${hours}:${minutesStr} ${ampm}`;
    };

    const calculateTotals = (seats) => {
        return seats.reduce((totals, seat) => ({
            baseFare: totals.baseFare + (seat.seatRate.baseFare || 0),
            discountedBaseFare: totals.discountedBaseFare + (seat.seatRate.discountedBaseFare || seat.seatRate.baseFare ),
            tax: totals.tax + (seat.seatRate.totalTax || 0),
            discount: totals.discount + (seat.seatRate.baseFare - seat.seatRate.discountedBaseFare || 0),
            total: totals.total + (seat.seatRate.totalCost || 0)
        }), { 
            baseFare: 0, 
            discountedBaseFare: 0, 
            tax: 0, 
            discount: 0, 
            total: 0 
        });
    };

    const calculateGrandTotal = (details) => {
        if (!details || !details.seats) return "0.00";

        const totals = calculateTotals(details.seats);
        const pointsValue = details.pointsApplied ? details.pointsApplied / 100 : 0;
        const promoDiscount = details.validatedPromo?.discountDetails?.discountAmount || 0;

        return (
            totals.discountedBaseFare +
            totals.tax -
            pointsValue -
            promoDiscount
        ).toFixed(2);
    };

    const calculateTotalDiscounts = () => {
        if (!reservationDetails?.seats) return { totalDiscount: 0, hasDiscounts: false };

        const totalDiscount = reservationDetails.seats.reduce((acc, seat) => {
            const basePrice = seat.seatRate.baseFare || 0;
            const discountedPrice = seat.seatRate.discountedBaseFare || basePrice;
            return acc + (basePrice - discountedPrice);
        }, 0);

        return {
            totalDiscount,
            hasDiscounts: totalDiscount > 0
        };
    };

    return (
        <View style={styles.bookFormContainer}>
            {message && <Text style={styles.message}>{message}</Text>}
            {error && <Text style={styles.error}>{error}</Text>}
            
            <Text style={styles.title}>Booking Confirmation</Text>
            
            <Text style={styles.text}>
                Enter comma separated emails, if you would like to send confirmation to multiple emails
            </Text>
            <TextInput
                style={styles.input}
                placeholder="Enter Email Address"
                keyboardType="email-address"
                value={email}
                onChangeText={setEmail}
            />

            <View style={styles.buttonContainer}>
                <TouchableOpacity style={styles.button} onPress={handleSendEmail}>
                    <Text style={styles.buttonText}>Send Email</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={handlePrint}>
                    <Text style={styles.buttonText}>Print</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={onHome}>
                    <Text style={styles.buttonText}>Home</Text>
                </TouchableOpacity>
            </View>

            {reservationDetails && (
                <View style={styles.detailsContainer}>
                    <Text style={styles.detailsTitle}>Reservation Details</Text>
                    
                    <Text style={styles.detailsText}>
                        BYGBUS PNR: 
                        <TouchableOpacity onPress={handleViewReservation}>
                            <Text style={[styles.value, styles.hyperlink]}>
                                {bookSummary.confirmationCode}
                            </Text>
                        </TouchableOpacity>
                    </Text>
                    <Text style={styles.detailsText}>
                        Operator PNR: {reservationDetails.operatorPNR}
                    </Text>
                    <Text style={styles.detailsText}>
                        Transaction ID: {bookSummary.paymentId}
                    </Text>
                    <Text style={styles.detailsText}>
                        Service Number: {reservationDetails.busAvailability.serviceIdentifier}
                    </Text>
                    
                    <Text style={[
                        styles.detailsText,
                        reservationDetails.status === "BOOKED" ? styles.statusBooked :
                        reservationDetails.status === "CANCELLED" ? styles.statusCancelled :
                        styles.statusOther
                    ]}>
                        Status: {reservationDetails.status}
                    </Text>

                    <View style={styles.section}>
                        <Text style={styles.sectionHeader}>Travel/Operator</Text>
                        <Text style={styles.detailText}>
                            Operator: {reservationDetails.busAvailability.operator}
                            ({reservationDetails.busAvailability.bus.name})
                        </Text>
                        <Text style={styles.detailText}>From: {reservationDetails.origin}</Text>
                        <Text style={styles.detailText}>To: {reservationDetails.destination}</Text>
                        <Text style={styles.detailText}>Date: {reservationDetails.travelDate}</Text>
                        <Text style={styles.detailText}>
                            Departure: {formatTime(reservationDetails.busAvailability.departureTime)}
                        </Text>
                        <Text style={styles.detailText}>
                            Arrival: {formatTime(reservationDetails.busAvailability.arrivalTime)}
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.sectionHeader}>Contact Details</Text>
                        <Text style={styles.detailText}>
                            Email: {reservationDetails.contactEmail}
                        </Text>
                        <Text style={styles.detailText}>
                            Phone: {reservationDetails.contactNumber}
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.sectionHeader}>Passenger Details</Text>
                        <View style={styles.passengersContainer}>
                            {reservationDetails.passengersInfo.map((passenger, index) => (
                                <View key={index} style={styles.passengerContainer}>
                                    <Text style={styles.passengerHeader}>
                                        Passenger {index + 1}
                                    </Text>
                                    <Text style={styles.detailText}>
                                        Seat: {passenger.seat.seatNo}
                                    </Text>
                                    <Text style={styles.detailText}>
                                        Name: {passenger.firstName} {passenger.lastName}
                                    </Text>
                                    <Text style={styles.detailText}>
                                        Age: {passenger.age}
                                    </Text>
                                    <Text style={styles.detailText}>
                                        Gender: {passenger.gender}
                                    </Text>
                                    <Text style={[
                                        styles.seatStatus,
                                        passenger.seat.seatStatus === "BOOKED" ? 
                                        styles.statusBooked : styles.statusCancelled
                                    ]}>
                                        {passenger.seat.seatStatus}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.sectionHeader}>Boarding/Drop-off Details</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.pointContainer}>
                                <Text style={styles.subHeader}>Boarding Point</Text>
                                <Text style={styles.detailText}>
                                    Location: {reservationDetails.boardingPoint.pointName}
                                </Text>
                                <Text style={styles.detailText}>
                                    Landmark: {reservationDetails.boardingPoint.landMark}
                                </Text>
                                <Text style={styles.detailText}>
                                    Contact: {reservationDetails.boardingPoint.contactNumber}
                                </Text>
                                <Text style={styles.detailText}>
                                    Time: {formatTime(reservationDetails.boardingPoint.arrivalTime)}
                                </Text>
                            </View>

                            <View style={styles.pointContainer}>
                                <Text style={styles.subHeader}>Drop-off Point</Text>
                                <Text style={styles.detailText}>
                                    Location: {reservationDetails.droppingPoint.pointName}
                                </Text>
                                <Text style={styles.detailText}>
                                    Landmark: {reservationDetails.droppingPoint.landMark}
                                </Text>
                                <Text style={styles.detailText}>
                                    Contact: {reservationDetails.droppingPoint.contactNumber}
                                </Text>
                                <Text style={styles.detailText}>
                                    Time: {formatTime(reservationDetails.droppingPoint.arrivalTime)}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                        {reservationDetails.busAvailability.cancellationPolicy?.cancellationPenalty.map((penalty, index) => (
                            <View key={index} style={styles.penaltyContainer}>
                                <Text style={styles.detailText}>
                                    Between {penalty.from ? 
                                        convertTo12HourFormat(penalty.from) : 'Departure'
                                    } and {penalty.to ? 
                                        convertTo12HourFormat(penalty.to) : 'Booked Date/time'
                                    }:
                                    {penalty.refundable ?
                                        ` ${penalty.chargeType === 'AMOUNT' ? '₹' : ''}${penalty.cancellationCharge}${penalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged` :
                                        ' Non Refundable'
                                    }
                                </Text>
                            </View>
                        ))}
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.sectionHeader}>Cost Breakdown</Text>
                        {reservationDetails.seats.map((seat, index) => (
                            <View key={index} style={styles.costDetailContainer}>
                                <Text style={styles.seatHeader}>Seat {seat.seatNo}</Text>
                                <View style={styles.costBreakdown}>
                                    <Text style={styles.costItem}>
                                        Base Fare: ₹{seat.seatRate.baseFare}
                                    </Text>
                                    {seat.seatRate.baseFare > seat.seatRate.discountedBaseFare && (
                                        <Text style={styles.discountText}>
                                            Discount: -₹{(seat.seatRate.baseFare - 
                                            seat.seatRate.discountedBaseFare).toFixed(2)}
                                        </Text>
                                    )}
                                    <Text style={styles.costItem}>
                                        Tax: ₹{seat.seatRate.totalTax}
                                    </Text>
                                    <Text style={styles.seatTotal}>
                                        Total: ₹{seat.seatRate.totalCost}
                                    </Text>
                                </View>
                            </View>
                        ))}

                        <View style={styles.totalSummaryContainer}>
                            <View style={styles.summaryRow}>
                                <Text style={styles.summaryLabel}>Subtotal:</Text>
                                <Text style={styles.summaryValue}>
                                    ₹{reservationDetails.seats.reduce((sum, seat) => 
                                        sum + seat.seatRate.baseFare, 0).toFixed(2)}
                                </Text>
                            </View>

                            {calculateTotalDiscounts().hasDiscounts && (
                                <View style={styles.summaryRow}>
                                    <Text style={[styles.summaryLabel, styles.discountLabel]}>
                                        Seat Discounts:
                                    </Text>
                                    <Text style={styles.discountValue}>
                                        -₹{calculateTotalDiscounts().totalDiscount.toFixed(2)}
                                    </Text>
                                </View>
                            )}

                            {reservationDetails.validatedPromo && (
                                <View style={styles.promoContainer}>
                                    <View style={styles.promoHeader}>
                                        <Text style={styles.promoCode}>
                                            Promo Applied: {reservationDetails.validatedPromo.offerCode}
                                        </Text>
                                        {reservationDetails.validatedPromo.discountDetails.discountType === 'PERCENTAGE' && (
                                            <Text style={styles.promoPercentage}>
                                                ({reservationDetails.validatedPromo.discountDetails.discountValue}% OFF)
                                            </Text>
                                        )}
                                    </View>
                                    <Text style={styles.promoDiscount}>
                                        Promo Discount: -₹{reservationDetails.validatedPromo.discountDetails.discountAmount.toFixed(2)}
                                    </Text>
                                </View>
                            )}

                            {reservationDetails.pointsApplied > 0 && (
                                <View style={styles.pointsContainer}>
                                    <View style={styles.pointsHeader}>
                                        <Text style={styles.pointsText}>
                                            Reward Points Applied: {reservationDetails.pointsApplied} points
                                        </Text>
                                    </View>
                                    <Text style={styles.pointsValue}>
                                        Points Value: -₹{(reservationDetails.pointsApplied / 100).toFixed(2)}
                                    </Text>
                                </View>
                            )}

                            <View style={styles.summaryRow}>
                                <Text style={styles.summaryLabel}>Total Taxes:</Text>
                                <Text style={styles.summaryValue}>
                                    ₹{reservationDetails.seats.reduce((sum, seat) => 
                                        sum + seat.seatRate.totalTax, 0).toFixed(2)}
                                </Text>
                            </View>

                            <View style={styles.grandTotalContainer}>
                                <Text style={styles.grandTotalLabel}>Grand Total:</Text>
                                <Text style={styles.grandTotalValue}>
                                ₹{calculateGrandTotal(reservationDetails)}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    bookFormContainer: {
        flex: 1,
        padding: 20,
        backgroundColor: '#f5f5f5',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
        color: '#333',
    },
    text: {
        fontSize: 12,
        fontStyle: 'italic',
        marginBottom: 10,
        color: '#666',
    },
    input: {
        height: 40,
        borderColor: '#ddd',
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 12,
        marginBottom: 20,
        backgroundColor: '#fff',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 12,
        marginBottom: 20,
    },
    button: {
        backgroundColor: '#0066cc',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        minWidth: 100,
        elevation: 2,
    },
    buttonText: {
        color: '#fff',
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'center',
    },
    message: {
        fontSize: 16,
        color: '#28a745',
        textAlign: 'center',
        marginBottom: 10,
    },
    error: {
        fontSize: 16,
        color: '#dc3545',
        textAlign: 'center',
        marginBottom: 10,
    },
    detailsContainer: {
        backgroundColor: '#fff',
        borderRadius: 12,
        padding: 20,
        elevation: 3,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
    },
    detailsTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 16,
        color: '#333',
        textAlign: 'center',
    },
    detailsText: {
        fontSize: 15,
        marginBottom: 8,
        color: '#444',
    },
    hyperlink: {
        color: '#0066cc',
        textDecorationLine: 'underline',
    },
    section: {
        marginTop: 20,
        padding: 16,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: '600',
        marginBottom: 12,
        color: '#333',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        paddingBottom: 8,
    },
    passengersContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 16,
    },
    passengerContainer: {
        flex: 1,
        minWidth: 280,
        backgroundColor: '#fff',
        padding: 12,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    passengerHeader: {
        fontSize: 16,
        fontWeight: '600',
        marginBottom: 8,
        color: '#333',
    },
    statusBooked: {
        color: '#28a745',
        fontWeight: '600',
    },
    statusCancelled: {
        color: '#dc3545',
        fontWeight: '600',
    },
    statusOther: {
        color: '#ffc107',
        fontWeight: '600',
    },
    rowContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 20,
    },
    pointContainer: {
        flex: 1,
        minWidth: 280,
        backgroundColor: '#fff',
        padding: 12,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    subHeader: {
        fontSize: 16,
        fontWeight: '600',
        marginBottom: 8,
        color: '#333',
    },
    costDetailContainer: {
        marginBottom: 16,
        padding: 12,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    seatHeader: {
        fontSize: 16,
        fontWeight: '600',
        marginBottom: 8,
        color: '#333',
    },
    costBreakdown: {
        marginLeft: 12,
    },
    costItem: {
        fontSize: 14,
        color: '#666',
        marginBottom: 4,
    },
    discountText: {
        fontSize: 14,
        color: '#28a745',
        marginBottom: 4,
    },
    seatTotal: {
        fontSize: 15,
        fontWeight: '500',
        color: '#333',
        marginTop: 4,
    },
    totalSummaryContainer: {
        marginTop: 20,
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    summaryLabel: {
        fontSize: 15,
        color: '#666',
    },
    summaryValue: {
        fontSize: 15,
        fontWeight: '500',
        color: '#333',
    },
    discountLabel: {
        color: '#28a745',
    },
    discountValue: {
        color: '#28a745',
        fontWeight: '500',
    },
    promoContainer: {
        marginVertical: 12,
        padding: 12,
        backgroundColor: '#e8f5e9',
        borderRadius: 8,
    },
    promoHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    promoCode: {
        fontSize: 14,
        color: '#2e7d32',
        fontWeight: '500',
    },
    promoPercentage: {
        fontSize: 14,
        color: '#2e7d32',
        marginLeft: 8,
    },
    promoDiscount: {
        fontSize: 15,
        fontWeight: '600',
        color: '#2e7d32',
    },
    grandTotalContainer: {
        marginTop: 12,
        paddingTop: 12,
        borderTopWidth: 1,
        borderTopColor: '#eee',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    grandTotalLabel: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    grandTotalValue: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    penaltyContainer: {
        marginBottom: 8,
    },
    pointsContainer: {
        marginVertical: 12,
        padding: 12,
        backgroundColor: '#e3f2fd',
        borderRadius: 8,
    },
    pointsHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    pointsText: {
        fontSize: 14,
        color: '#1565c0',
        fontWeight: '500',
    },
    pointsValue: {
        fontSize: 15,
        fontWeight: '600',
        color: '#1565c0',
    }
});

export default BusBookingConfirmation;