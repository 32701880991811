import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, ScrollView, Modal } from "react-native";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import Slider from '@react-native-community/slider';

const { width } = Dimensions.get('window');

const BusAvailabilitiesFilterSort = ({ onFilterChange, onSortChange, busAvailabilities, operators, boardingPoints, dropOffPoints }) => {
    const initialFilters = {
        ac: false,
        nonAc: false,
        sleeper: false,
        semiSleeper: false,
        pushback: false,
        minPrice: 100,
        maxPrice: 10000,
        operators: [],
        boardingPoints: [],
        dropOffPoints: [],
    };

    const initialSortBy = {
        price: null,
        departure: null,
        arrival: null
    };

    const [filters, setFilters] = useState(initialFilters);
    const [sortBy, setSortBy] = useState(initialSortBy);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState(null);

    const sortOptions = [
        {label: 'Price (Low to High)', value: 'Price ↑', category: 'price'},
        {label: 'Price (High to Low)', value: 'Price ↓', category: 'price'},
        {label: 'Earliest Departure', value: 'Departure ↑', category: 'departure'},
        {label: 'Latest Departure', value: 'Departure ↓', category: 'departure'},
        {label: 'Earliest Arrival', value: 'Arrival ↑', category: 'arrival'},
        {label: 'Latest Arrival', value: 'Arrival ↓', category: 'arrival'},
    ];

    // Sort operators, boarding points, and drop-off points alphabetically
    const sortedOperators = [...operators].sort((a, b) => a.localeCompare(b));
    const sortedBoardingPoints = [...boardingPoints].sort((a, b) => a.localeCompare(b));
    const sortedDropOffPoints = [...dropOffPoints].sort((a, b) => a.localeCompare(b));

    useEffect(() => {
        const countSelectedFilters = () => {
            const filterKeys = ['ac', 'nonAc', 'sleeper', 'semiSleeper', 'pushback'];
            let count = filterKeys.filter(key => filters[key]).length;
            count += filters.operators.length;
            count += filters.boardingPoints.length;
            count += filters.dropOffPoints.length;
            count += Object.values(sortBy).filter(Boolean).length;
            setSelectedFilterCount(count);
        };
        countSelectedFilters();
    }, [filters, sortBy]);

    const handlePress = (filter) => {
        setFilters(prevFilters => ({ ...prevFilters, [filter]: !prevFilters[filter] }));
    };

    const handleSliderChange = (field, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    const applyFilters = () => {
        onFilterChange(filters);
        onSortChange(sortBy);
        setShowFilters(false);
    };

    const clearFilters = () => {
        setFilters(initialFilters);
        setSortBy(initialSortBy);
        onFilterChange(initialFilters);
        onSortChange(initialSortBy);
        setShowFilters(false);
    };

    const openModal = (type) => {
        setModalType(type);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setModalType(null);
    };

    const handleMultiSelect = (item) => {
        setFilters(prevFilters => {
            const currentSelection = prevFilters[modalType];
            const updatedSelection = currentSelection.includes(item)
                ? currentSelection.filter(i => i !== item)
                : [...currentSelection, item];
            return { ...prevFilters, [modalType]: updatedSelection };
        });
    };

    const handleSortSelect = (item) => {
        setSortBy(prevSortBy => {
            const newSortBy = { ...prevSortBy };
            if (newSortBy[item.category] === item.value) {
                newSortBy[item.category] = null;
            } else {
                newSortBy[item.category] = item.value;
            }
            return newSortBy;
        });
    };

    const renderModalContent = () => {
        let options;
        switch (modalType) {
            case 'operators':
                options = sortedOperators;
                break;
            case 'boardingPoints':
                options = sortedBoardingPoints;
                break;
            case 'dropOffPoints':
                options = sortedDropOffPoints;
                break;
            case 'sortBy':
                options = sortOptions;
                break;
            default:
                return null;
        }

        return (
            <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>{modalType.charAt(0).toUpperCase() + modalType.slice(1).replace(/([A-Z])/g, ' $1')}</Text>
                <ScrollView>
                    {options.map((item) => (
                        <TouchableOpacity
                            key={modalType === 'sortBy' ? item.value : item}
                            style={[
                                styles.modalItem,
                                modalType === 'sortBy'
                                    ? sortBy[item.category] === item.value && styles.selectedModalItem
                                    : filters[modalType].includes(item) && styles.selectedModalItem
                            ]}
                            onPress={() => modalType === 'sortBy' ? handleSortSelect(item) : handleMultiSelect(item)}
                        >
                            <Text style={[
                                styles.modalItemText,
                                modalType === 'sortBy'
                                    ? sortBy[item.category] === item.value && styles.selectedModalItemText
                                    : filters[modalType].includes(item) && styles.selectedModalItemText
                            ]}>
                                {modalType === 'sortBy' ? item.label : item}
                            </Text>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
                <TouchableOpacity style={styles.modalCloseButton} onPress={closeModal}>
                    <Text style={styles.modalCloseButtonText}>Close</Text>
                </TouchableOpacity>
            </View>
        );
    };

    const getSelectedItemsText = (type) => {
        const selectedItems = filters[type];
        if (selectedItems.length === 0) return `Select ${type.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
        if (selectedItems.length === 1) return selectedItems[0];
        return `${selectedItems.length} selected`;
    };

    const getSelectedSortOptionsText = () => {
        const selectedOptions = Object.values(sortBy).filter(Boolean);
        if (selectedOptions.length === 0) return 'Select sorting options';
        return selectedOptions.map(value => sortOptions.find(option => option.value === value).label).join(', ');
    };

    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.header} onPress={() => setShowFilters(!showFilters)}>
                <Text style={styles.headerText}>Filter & Sort</Text>
                <View style={styles.headerRight}>
                    <Text style={styles.filterCount}>{selectedFilterCount}</Text>
                    <Ionicons 
                        name={showFilters ? "chevron-up" : "chevron-down"} 
                        size={24} 
                        color="#333" 
                    />
                </View>
            </TouchableOpacity>
            {showFilters && (
                <ScrollView style={styles.filterContent}>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Bus Type</Text>
                        <View style={styles.buttonGroup}>
                            {[
                                { key: 'ac', icon: 'snow', label: 'AC', IconComponent: Ionicons },
                                { key: 'nonAc', icon: 'snow-outline', label: 'Non AC', IconComponent: Ionicons },
                                { key: 'sleeper', icon: 'airline-seat-individual-suite', label: 'Sleeper', IconComponent: MaterialIcons },
                                { key: 'semiSleeper', icon: 'airline-seat-flat-angled', label: 'Semi Sleeper', IconComponent: MaterialIcons },
                                { key: 'pushback', icon: 'seat-recline-extra', label: 'Pushback', IconComponent: MaterialCommunityIcons },
                            ].map(({ key, icon, label, IconComponent }) => (
                                <TouchableOpacity 
                                    key={key}
                                    style={[styles.filterButton, filters[key] && styles.selectedButton]} 
                                    onPress={() => handlePress(key)}
                                >
                                    <IconComponent name={icon} size={24} color={filters[key] ? "#fff" : "#333"} />
                                    <Text style={[styles.buttonText, filters[key] && styles.selectedButtonText]}>{label}</Text>
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Price Range</Text>
                        <Slider
                            style={styles.slider}
                            minimumValue={100}
                            maximumValue={10000}
                            step={100}
                            minimumTrackTintColor="#5EBC67"
                            maximumTrackTintColor="#ddd"
                            thumbTintColor="#5EBC67"
                            value={filters.maxPrice}
                            onValueChange={value => handleSliderChange('maxPrice', value)}
                        />
                        <Text style={styles.sliderValue}>{`₹${filters.minPrice} - ₹${filters.maxPrice}`}</Text>
                    </View>
                    
                    {['operators', 'boardingPoints', 'dropOffPoints'].map((type) => (
                        <View key={type} style={styles.section}>
                            <Text style={styles.sectionTitle}>
                                {type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, ' $1')}
                            </Text>
                            <TouchableOpacity style={styles.dropdownButton} onPress={() => openModal(type)}>
                                <Text style={styles.dropdownButtonText}>
                                    {getSelectedItemsText(type)}
                                </Text>
                                <Ionicons name="chevron-down" size={24} color="#333" />
                            </TouchableOpacity>
                        </View>
                    ))}

                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Sort By</Text>
                        <TouchableOpacity style={styles.dropdownButton} onPress={() => openModal('sortBy')}>
                            <Text style={styles.dropdownButtonText}>
                                {getSelectedSortOptionsText()}
                            </Text>
                            <Ionicons name="chevron-down" size={24} color="#333" />
                        </TouchableOpacity>
                    </View>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity 
                            onPress={clearFilters} 
                            style={styles.clearButton}
                        >
                            <Text style={styles.clearButtonText}>Clear Filters</Text>
                        </TouchableOpacity>
                        <TouchableOpacity 
                            onPress={applyFilters} 
                            style={styles.applyButton}
                        >
                            <Text style={styles.applyButtonText}>Apply Filters</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            )}

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={closeModal}
            >
                <View style={styles.modalContainer}>
                    {renderModalContent()}
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        overflow: 'hidden',
        margin: 10,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
        width: '90%',
        marginHorizontal: '5%',
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 15,
        backgroundColor: '#059669',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    headerText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterCount: {
        backgroundColor: '#5EBC67',
        color: '#fff',
        borderRadius: 15,
        paddingHorizontal: 8,
        paddingVertical: 2,
        marginRight: 10,
        fontSize: 14,
    },
    filterContent: {
        padding: 15,
    },
    section: {
        marginBottom: 20,
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333',
    },
    buttonGroup: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    filterButton: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 12,
        marginRight: 10,
        marginBottom: 10,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    selectedButton: {
        backgroundColor: '#5EBC67',
        borderColor: '#5EBC67',
    },
    buttonText: {
        marginLeft: 5,
        color: '#333',
    },
    selectedButtonText: {
        color: '#fff',
    },
    slider: {
        width: '100%',
        height: 40,
    },
    sliderValue: {
        textAlign: 'center',
        marginTop: 5,
        color: '#666',
    },
    dropdownButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 10,
    },
    dropdownButtonText: {
        color: '#333',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
        gap: 10,
    },
    clearButton: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 25,
        paddingVertical: 12,
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#5EBC67',
    },
    clearButtonText: {
        color: '#5EBC67',
        fontSize: 16,
        fontWeight: 'bold',
    },
    applyButton: {
        flex: 1,
        backgroundColor: '#5EBC67',
        borderRadius: 25,
        paddingVertical: 12,
        alignItems: 'center',
    },
    applyButtonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        width: '80%',
        maxHeight: '80%',
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 15,
        textAlign: 'center',
    },
    modalItem: {
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    selectedModalItem: {
        backgroundColor: '#e6f7ff',
    },
    modalItemText: {
        fontSize: 16,
    },
    selectedModalItemText: {
        color: '#5EBC67',
        fontWeight: 'bold',
    },
    modalCloseButton: {
        marginTop: 20,
        padding: 10,
        backgroundColor: '#5EBC67',
        borderRadius: 5,
        alignItems: 'center',
    },
    modalCloseButtonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
});

export default BusAvailabilitiesFilterSort;