import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput, ScrollView, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import Checkbox from 'expo-checkbox';

const BusBookingForm = ({ bookSummary, handleBookSummaryChange, onProceed, onClickBack }) => {
    // State Management
    const [selectedBoardingPoint, setSelectedBoardingPoint] = useState(bookSummary.boardingPoint);
    const [selectedDropOffPoint, setSelectedDropOffPoint] = useState(bookSummary.dropOffPoint);
    const [contactEmail, setContactEmail] = useState(bookSummary.contactEmail);
    const [contactPhone, setContactPhone] = useState(bookSummary.contactPhone);
    const [errors, setErrors] = useState({});

    // Constants
    const titles = ['Mr', 'Mrs', 'Ms'];

    // Initialize passengers with default values
    const [passengers, setPassengers] = useState(
        bookSummary.seats.map((seat, index) => ({
            firstName: '',
            lastName: '',
            gender: seat.seatStatus === 'AVAILABLE_MALE' ? 'Male' : 
                    seat.seatStatus === 'AVAILABLE_FEMALE' ? 'Female' : '',
            age: '',
            title: '',
            isPrimary: index === 0, // First passenger is primary by default
            seat
        }))
    );

    // Event Handlers
    const handleBoardingPointPress = (point) => {
        setSelectedBoardingPoint(point === selectedBoardingPoint ? '' : point);
        setErrors((prev) => ({ ...prev, boardingPoint: '' }));
    };

    const handleDropOffPointPress = (point) => {
        setSelectedDropOffPoint(point === selectedDropOffPoint ? '' : point);
        setErrors((prev) => ({ ...prev, dropOffPoint: '' }));
    };

    const handlePassengerChange = (index, key, value) => {
        const updatedPassengers = [...passengers];
        if (!updatedPassengers[index]) {
            updatedPassengers[index] = {
                firstName: '',
                lastName: '',
                gender: '',
                age: '',
                title: '',
                isPrimary: false,
                seat: bookSummary.seats[index]
            };
        }
        
        // Handle primary passenger selection
        if (key === 'isPrimary' && value === true) {
            updatedPassengers.forEach((passenger, i) => {
                passenger.isPrimary = i === index;
            });
        }
        
        updatedPassengers[index][key] = value;
        setPassengers(updatedPassengers);
        setErrors((prev) => ({ ...prev, [`passenger_${index}_${key}`]: '' }));
    };

    const handleBack = () => {
        setPassengers([]);
        setSelectedBoardingPoint('');
        setSelectedDropOffPoint('');
        setContactEmail('');
        setContactPhone('');
        onClickBack();
    };

    const handleSubmit = () => {
        handleBookSummaryChange({
            ...bookSummary,
            boardingPoint: selectedBoardingPoint,
            dropOffPoint: selectedDropOffPoint,
            contactEmail,
            contactPhone,
            passengers
        });
    };

    // Utility Functions
    const getSeatType = (seat) => {
        const seatTypes = {
            'REGULAR': 'Regular Seat',
            'SINGLE_REGULAR': 'Single Regular Seat',
            'PUSHBACK': 'Pushback',
            'SEMI_SLEEPER': 'Semi Sleeper',
            'SLEEPER': 'Sleeper',
            'SINGLE_LOWER_SLEEPER': 'Single Lower Sleeper',
            'SINGLE_UPPER_SLEEPER': 'Single Upper Sleeper',
            'LOWER_SLEEPER': 'Lower Sleeper',
            'UPPER_SLEEPER': 'Upper Sleeper'
        };
        return seatTypes[seat.seatType] || 'Seat';
    };

    const validateForm = () => {
        const newErrors = {};

        // Contact validation
        if (!contactEmail) newErrors.contactEmail = 'Email is required';
        if (!contactPhone) newErrors.contactPhone = 'Phone is required';

        // Points validation
        if (!selectedBoardingPoint) newErrors.boardingPoint = 'Boarding point is required';
        if (!selectedDropOffPoint) newErrors.dropOffPoint = 'Drop off point is required';

        // Primary passenger validation
        if (!passengers.some(p => p.isPrimary)) {
            newErrors.primaryPassenger = 'At least one passenger must be marked as primary';
        }

        // Passenger details validation
        passengers.forEach((passenger, index) => {
            ['firstName', 'lastName', 'age', 'gender', 'title'].forEach(field => {
                if (!passenger[field]) {
                    newErrors[`passenger_${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
                }
            });
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onReview = () => {
        if (validateForm()) {
            handleSubmit();
            onProceed();
        }
    };

    // Render Functions
    const renderContactDetails = () => (
        <View style={styles.contactContainer}>
            <Text style={styles.containerBorderText}>
                Contact Details <Text style={styles.asterisk}> *</Text>
            </Text>
            <View style={styles.contactRowContainer}>
                <View style={styles.contactDetailContainer}>
                    <Text style={styles.detailHeader}>
                        Email <Text style={styles.asterisk}> *</Text>
                    </Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Contact Email"
                        onChangeText={setContactEmail}
                        value={contactEmail}
                    />
                    {errors.contactEmail && (
                        <Text style={styles.errorText}>{errors.contactEmail}</Text>
                    )}
                </View>
                <View style={styles.contactDetailContainer}>
                    <Text style={styles.detailHeader}>
                        Phone Number <Text style={styles.asterisk}> *</Text>
                    </Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Contact Phone"
                        onChangeText={setContactPhone}
                        value={contactPhone}
                        keyboardType="phone-pad"
                    />
                    {errors.contactPhone && (
                        <Text style={styles.errorText}>{errors.contactPhone}</Text>
                    )}
                </View>
            </View>
            <Text style={styles.contactCommunicationText}>
                Above provided contact information would be used to send Email/Whatsapp communications regarding booking
            </Text>
        </View>
    );

    const renderPassengerTitle = (index) => (
        <View style={styles.titleContainer}>
            <Text style={styles.detailHeader}>
                Title<Text style={styles.asterisk}> *</Text>
            </Text>
            <select 
                value={passengers[index]?.title || ''} 
                onChange={(e) => handlePassengerChange(index, 'title', e.target.value)}
                style={styles.selectInput}
            >
                <option value="">Select Title</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
            </select>
            {errors[`passenger_${index}_title`] && (
                <Text style={styles.errorText}>
                    {errors[`passenger_${index}_title`]}
                </Text>
            )}
        </View>
    );

    const renderPrimaryPassenger = (index) => (
        <View style={styles.primaryContainer}>
            <View style={styles.checkboxContainer}>
                <input
                    type="checkbox"
                    checked={passengers[index]?.isPrimary || false}
                    onChange={(e) => handlePassengerChange(index, 'isPrimary', e.target.checked)}
                    style={styles.checkbox}
                />
                <Text style={styles.checkboxLabel}>Primary Passenger</Text>
            </View>
        </View>
    );

    const renderPassengerGender = (index, selectedSeat) => (
        <View style={styles.genderContainer}>
            <Text style={styles.detailHeader}>
                Gender<Text style={styles.asterisk}> *</Text>
            </Text>
            <View style={styles.genderButtons}>
                {selectedSeat.seatStatus === 'AVAILABLE_MALE' || selectedSeat.seatStatus === 'AVAILABLE_FEMALE' ? (
                    <Text style={styles.nonEditableGender}>
                        {selectedSeat.seatStatus === 'AVAILABLE_MALE' ? 'Male' : 'Female'}
                    </Text>
                ) : (
                    <>
                        <TouchableOpacity
                            style={[
                                styles.genderButton,
                                passengers[index]?.gender === 'Male' && styles.maleSelected,
                            ]}
                            onPress={() => handlePassengerChange(index, 'gender', 'Male')}
                        >
                            <Text style={[
                                styles.genderButtonText,
                                passengers[index]?.gender === 'Male' && styles.selectedGenderButtonText,
                            ]}>
                                Male
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[
                                styles.genderButton,
                                passengers[index]?.gender === 'Female' && styles.femaleSelected,
                            ]}
                            onPress={() => handlePassengerChange(index, 'gender', 'Female')}
                        >
                            <Text style={[
                                styles.genderButtonText,
                                passengers[index]?.gender === 'Female' && styles.selectedGenderButtonText,
                            ]}>
                                Female
                            </Text>
                        </TouchableOpacity>
                    </>
                )}
            </View>
            {errors[`passenger_${index}_gender`] && (
                <Text style={styles.errorText}>
                    {errors[`passenger_${index}_gender`]}
                </Text>
            )}
        </View>
    );

    const renderPassengerDetails = () => (
        <View style={styles.passengersContainer}>
            <Text style={styles.containerBorderText}>
                Passenger Details <Text style={styles.asterisk}> *</Text>
            </Text>
            {errors.primaryPassenger && (
                <Text style={[styles.errorText, { marginTop: 16 }]}>{errors.primaryPassenger}</Text>
            )}
            {bookSummary.seats.map((selectedSeat, index) => (
                <View key={index} style={styles.passengerContact}>
                    <Text style={styles.containerBorderText}>Passenger {index + 1}</Text>
                    <Text style={styles.seatLabel}>
                        Seat {selectedSeat.seatNo} ({getSeatType(selectedSeat)})
                    </Text>
                    <View style={styles.passengerInformation}>
                        {renderPassengerTitle(index)}
                        {renderPrimaryPassenger(index)}

                        <View style={styles.nameContainer}>
                            <Text style={styles.detailHeader}>
                                First Name<Text style={styles.asterisk}> *</Text>
                            </Text>
                            <TextInput
                                style={styles.input}
                                placeholder="First Name"
                                onChangeText={(value) => handlePassengerChange(index, 'firstName', value)}
                                value={passengers[index]?.firstName || ''}
                            />
                            {errors[`passenger_${index}_firstName`] && (
                                <Text style={styles.errorText}>
                                    {errors[`passenger_${index}_firstName`]}
                                </Text>
                            )}
                        </View>

                        <View style={styles.nameContainer}>
                            <Text style={styles.detailHeader}>
                                Last Name<Text style={styles.asterisk}> *</Text>
                            </Text>
                            <TextInput
                                style={styles.input}
                                placeholder="Last Name"
                                onChangeText={(value) => handlePassengerChange(index, 'lastName', value)}
                                value={passengers[index]?.lastName || ''}
                            />
                            {errors[`passenger_${index}_lastName`] && (
                                <Text style={styles.errorText}>
                                    {errors[`passenger_${index}_lastName`]}
                                </Text>
                            )}
                        </View>

                        <View style={styles.ageContainer}>
                            <Text style={styles.detailHeader}>
                                Age<Text style={styles.asterisk}> *</Text>
                            </Text>
                            <TextInput
                                style={styles.input}
                                placeholder="Age"
                                onChangeText={(value) => handlePassengerChange(index, 'age', value)}
                                value={passengers[index]?.age || ''}
                                keyboardType="numeric"
                            />
                            {errors[`passenger_${index}_age`] && (
                                <Text style={styles.errorText}>
                                    {errors[`passenger_${index}_age`]}
                                </Text>
                            )}
                        </View>

                        {renderPassengerGender(index, selectedSeat)}
                    </View>
                </View>
            ))}
        </View>
    );

    const renderBoardingPoints = () => (
        <View style={{width: '50%'}}>
            <Text style={styles.listHeader}>
                Boarding Points<Text style={styles.asterisk}> *</Text>
            </Text>
            {errors.boardingPoint && (
                <Text style={styles.errorText}>{errors.boardingPoint}</Text>
            )}
            <ScrollView contentContainerStyle={styles.boardingPointsContainer}>
                {bookSummary.busAvailability.boardingPoints.map((item) => {
                    const arrivalTime = new Date(
                        ...item.arrivalTime
                    ).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                    });

                    return (
                        <TouchableOpacity
                            key={item.pointIdentifier}
                            onPress={() => handleBoardingPointPress(item)}
                        >
                            <View style={
                                selectedBoardingPoint.pointName === item.pointName
                                    ? styles.selectedPoint
                                    : styles.point
                            }>
                                <Text style={
                                    selectedBoardingPoint.pointName === item.pointName
                                        ? styles.selectedPointName
                                        : styles.pointName
                                }>
                                    {item.pointName} ({arrivalTime})
                                </Text>
                                <Text style={
                                    selectedBoardingPoint.pointName === item.pointName
                                        ? styles.selectedPointLandMark
                                        : styles.landMark
                                }>
                                    {item.landMark}
                                </Text>
                                </View>
                        </TouchableOpacity>
                    );
                })}
            </ScrollView>
        </View>
    );

    const renderDropOffPoints = () => (
        <View style={{width: '50%'}}>
            <Text style={styles.listHeader}>
                Dropoff Points<Text style={styles.asterisk}> *</Text>
            </Text>
            {errors.dropOffPoint && (
                <Text style={styles.errorText}>{errors.dropOffPoint}</Text>
            )}
            <ScrollView contentContainerStyle={styles.dropOffPointsContainer}>
                {bookSummary.busAvailability.dropOffPoints.map((item) => {
                    const arrivalTime = new Date(
                        ...item.arrivalTime
                    ).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                    });

                    return (
                        <TouchableOpacity
                            key={item.pointIdentifier}
                            onPress={() => handleDropOffPointPress(item)}
                        >
                            <View style={
                                selectedDropOffPoint.pointName === item.pointName
                                    ? styles.selectedPoint
                                    : styles.point
                            }>
                                <Text style={
                                    selectedDropOffPoint.pointName === item.pointName
                                        ? styles.selectedPointName
                                        : styles.pointName
                                }>
                                    {item.pointName} ({arrivalTime})
                                </Text>
                                <Text style={
                                    selectedDropOffPoint.pointName === item.pointName
                                        ? styles.selectedPointLandMark
                                        : styles.landMark
                                }>
                                    {item.landMark}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    );
                })}
            </ScrollView>
        </View>
    );

    // Main Render
    return (
        <View style={styles.container}>
            <View style={styles.bookFormContainer}>
                {renderContactDetails()}
                {renderPassengerDetails()}
                
                <View style={styles.boardingDropOffContainer}>
                    <Text style={styles.containerBorderText}>
                        Boarding/Dropping Points
                    </Text>
                    <View style={styles.boardingDropOffPointsContainer}>
                        {renderBoardingPoints()}
                        {renderDropOffPoints()}
                    </View>
                </View>

                <View style={styles.actionContainer}>
                    <TouchableOpacity onPress={handleBack} style={styles.editButton}>
                        <Text style={styles.editButtonText}>Back</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onReview} style={styles.reviewButton}>
                        <Text style={styles.reviewButtonText}>Review</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
    },
    bookFormContainer: {
        width: '90%',
        padding: 24,
        backgroundColor: 'white',
        borderRadius: 12,
        borderWidth: 1,
        borderColor: '#E5E7EB',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 3,
    },
    contactContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#E5E7EB',
        padding: 16,
        marginVertical: 20,
        borderRadius: 8,
        backgroundColor: '#FFFFFF'
    },
    contactRowContainer:{
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    containerBorderText: {
        position: 'absolute',
        top: -10,
        left: 10,
        backgroundColor: 'white',
        paddingHorizontal: 8,
        fontSize: 14,
        fontWeight: '600',
        color: '#374151',
    },
    contactDetailContainer: {
        marginBottom: 16,
        width: '48%'
    },
    detailHeader: {
        fontSize: 14,
        fontWeight: '600',
        marginBottom: 6,
        color: '#374151',
    },
    input: {
        borderWidth: 1,
        borderColor: '#E5E7EB',
        padding: 12,
        borderRadius: 8,
        backgroundColor: '#F9FAFB',
        fontSize: 14,
    },
    passengersContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#E5E7EB',
        padding: 16,
        marginBottom: 20,
        borderRadius: 8,
    },
    passengerContact: {
        borderWidth: 1,
        borderColor: '#E5E7EB',
        padding: 16,
        borderRadius: 8,
        marginVertical: 10,
        backgroundColor: '#F9FAFB',
    },
    passengerInformation: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    seatLabel: {
        fontSize: 14,
        fontWeight: '600',
        marginBottom: 12,
        color: '#374151',
    },
    titleContainer: {
        width: '48%',
        marginBottom: 12,
    },
    titleButtons: {
        flexDirection: 'row',
        gap: 8,
    },
    titleButton: {
        flex: 1,
        padding: 10,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#E5E7EB',
        backgroundColor: 'white',
    },
    selectedTitleButton: {
        backgroundColor: '#5EBC67',
        borderColor: '#5EBC67',
    },
    titleButtonText: {
        color: '#374151',
        textAlign: 'center',
        fontSize: 14,
    },
    selectedTitleButtonText: {
        color: 'white',
        fontWeight: '500',
    },
    primaryContainer: {
        width: '48%',
        marginBottom: 12,
    },
    primaryButton: {
        padding: 10,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#E5E7EB',
        backgroundColor: 'white',
    },
    selectedPrimaryButton: {
        backgroundColor: '#5EBC67',
        borderColor: '#5EBC67',
    },
    primaryButtonText: {
        color: '#374151',
        textAlign: 'center',
        fontSize: 14,
    },
    selectedPrimaryButtonText: {
        color: 'white',
        fontWeight: '500',
    },
    nameContainer: {
        width: '48%',
        marginBottom: 12,
    },
    ageContainer: {
        width: '48%',
        marginBottom: 12,
    },
    genderContainer: {
        width: '48%',
        marginBottom: 12,
    },
    genderButtons: {
        flexDirection: 'row',
        gap: 8,
    },
    genderButton: {
        flex: 1,
        padding: 10,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#E5E7EB',
        backgroundColor: 'white',
    },
    nonEditableGender: {
        flex: 1,
        padding: 10,
        borderRadius: 8,
        backgroundColor: '#F3F4F6',
        borderWidth: 1,
        borderColor: '#E5E7EB',
        color: '#6B7280',
        textAlign: 'center',
    },
    maleSelected: {
        backgroundColor: '#0EA5E9',
        borderColor: '#0EA5E9',
    },
    femaleSelected: {
        backgroundColor: '#EC4899',
        borderColor: '#EC4899',
    },
    genderButtonText: {
        color: '#374151',
        textAlign: 'center',
        fontSize: 14,
    },
    selectedGenderButtonText: {
        color: 'white',
        fontWeight: '500',
    },
    boardingDropOffContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#E5E7EB',
        padding: 16,
        borderRadius: 8,
        marginBottom: 20,
    },
    boardingDropOffPointsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
        gap: 16,
    },
    boardingPointsContainer: {
        maxHeight: 300,
        paddingRight: 12,
    },
    dropOffPointsContainer: {
        maxHeight: 300,
        borderLeftWidth: 1,
        borderLeftColor: '#E5E7EB',
        paddingLeft: 12,
    },
    listHeader: {
        fontSize: 14,
        fontWeight: '600',
        marginBottom: 12,
        color: '#374151',
    },
    point: {
        marginVertical: 8,
        padding: 12,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#E5E7EB',
        backgroundColor: 'white',
    },
    pointName: {
        fontSize: 14,
        color: '#374151',
        fontWeight: '500',
        marginBottom: 4,
    },
    landMark: {
        fontSize: 12,
        color: '#6B7280',
        fontStyle: 'italic',
    },
    selectedPoint: {
        backgroundColor: '#5EBC67',
        borderColor: '#5EBC67',
        padding: 12,
        borderRadius: 8,
        marginVertical: 8,
    },
    selectedPointName: {
        color: 'white',
        fontWeight: '500',
        fontSize: 14,
        marginBottom: 4,
    },
    selectedPointLandMark: {
        color: 'white',
        fontSize: 12,
        opacity: 0.9,
    },
    actionContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 12,
        marginTop: 24,
    },
    reviewButton: {
        backgroundColor: '#5EBC67',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        minWidth: 120,
    },
    reviewButtonText: {
        color: 'white',
        fontWeight: '600',
        fontSize: 14,
        textAlign: 'center',
    },
    editButton: {
        backgroundColor: '#F3F4F6',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        minWidth: 120,
        borderWidth: 1,
        borderColor: '#E5E7EB',
    },
    editButtonText: {
        color: '#374151',
        fontWeight: '600',
        fontSize: 14,
        textAlign: 'center',
    },
    contactCommunicationText: {
        fontSize: 12,
        color: '#6B7280',
        fontStyle: 'italic',
        marginTop: 8,
    },
    asterisk: {
        color: '#EF4444',
    },
    errorText: {
        color: '#EF4444',
        fontSize: 12,
        marginTop: 4,
        fontWeight: '500',
    },
    pickerContainer: {
        borderWidth: 1,
        borderColor: '#E5E7EB',
        borderRadius: 8,
        backgroundColor: '#F9FAFB',
        overflow: 'hidden',
    },
    picker: {
        height: 50,
        width: '100%',
        backgroundColor: '#F9FAFB',
        marginTop: Platform.OS === 'android' ? -8 : 0,
    },
    pickerText: {
        paddingVertical: 12,
        paddingHorizontal: 12,
        color: '#374151',
    },

    selectInput: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#E5E7EB',
        borderRadius: 8,
        backgroundColor: '#F9FAFB',
        padding: 12,
        fontSize: 14,
        color: '#374151',
        outlineStyle: 'none', // Removes default focus outline
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        padding: 8,
    },
    checkbox: {
        width: 18,
        height: 18,
        cursor: 'pointer',
        accentColor: '#5EBC67', // Changes the checkbox color when checked
    },
    checkboxLabel: {
        fontSize: 14,
        color: '#374151',
        cursor: 'pointer',
    },
});

export default BusBookingForm;