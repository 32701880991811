import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, Button, Platform, StyleSheet, ScrollView, CheckBox, ActivityIndicator, Alert } from 'react-native';
import axios from 'axios';
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal';
import TermsAndConditionsModal from '../modals/TermsAndConditionsModal';
import CancellationRefundPolicyModal from '../modals/CancellationRefundPolicyModal';
import { format } from 'date-fns';
import { useUser } from './UserContext';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BookBookingReview = ({ initialBookSummary, handlePaymentSuccess, onEdit, handleBookSummaryChange }) => {
    const [bookSummary, setBookSummary] = useState(initialBookSummary);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Failed to confirm seat. Please try again');
    const { user, userPoints } = useUser();
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [CancellationRefundPolicyModalVisible, setCancellationRefundPolicyModalVisible] = useState(false);

    useEffect(() => {
        setBookSummary(initialBookSummary);
    }, [initialBookSummary]);

    useEffect(() => {
        if (Platform.OS === 'web') {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.async = true;
            script.onload = () => {
                console.log('Razorpay script loaded');
            };
            script.onerror = () => {
                console.error('Failed to load Razorpay script');
            };
            document.body.appendChild(script);
        }
    }, []);

    const calculateTotalDiscounts = () => {
        if (!bookSummary.seats) return { totalDiscount: 0, hasDiscounts: false };

        const totalDiscount = bookSummary.seats.reduce((acc, seat) => {
            const basePrice = seat.seatRate.baseFare || 0;
            const discountedPrice = seat.seatRate.discountedBaseFare || basePrice;
            return acc + (basePrice - discountedPrice);
        }, 0);

        return {
            totalDiscount,
            hasDiscounts: totalDiscount > 0
        };
    };

    const convertToPaise = (amountInRupees) => {
        return Math.round(parseFloat(amountInRupees) * 100);
    }

    const convertToRupees = (amountInPaise) => {
        return (amountInPaise / 100).toFixed(2);
    }

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${day}/${month}/${year} ${hours}:${minutesStr} ${ampm}`;
    };

    const openTermsModal = () => setTermsModalVisible(true);
    const openPrivacyModal = () => setPrivacyModalVisible(true);
    const openCancellationRefundPolicyModal = () => setCancellationRefundPolicyModalVisible(true);

    const closeTermsModal = () => setTermsModalVisible(false);
    const closePrivacyModal = () => setPrivacyModalVisible(false);
    const closeCancellationRefundPolicyModal = () => setCancellationRefundPolicyModalVisible(false);

    const handleSeatBlock = async () => {
        setLoading(true);
        setShowErrorMessage(false);
        try {
            const formattedDate = format(bookSummary.travelDate, 'yyyy-MM-dd');
            const requestBody = {
                contactNumber: bookSummary.contactPhone,
                contactEmail: bookSummary.contactEmail,
                tripIdentifier: bookSummary.busAvailability.tripIdentifier,
                serviceIdentifier: bookSummary.busAvailability.serviceIdentifier,
                origin: bookSummary.origin,
                destination: bookSummary.destination,
                travelDate: formattedDate,
                boardingPoint: bookSummary.boardingPoint,
                droppingPoint: bookSummary.dropOffPoint,
                seats: bookSummary.seats,
                passengersInfo: bookSummary.passengers,
                busAvailability: bookSummary.busAvailability,
                promoCode : bookSummary.validatedPromo ? bookSummary.validatedPromo.offerCode : null,
                userId: user ? user.userId : '',
                useRewardPoints: bookSummary.useRewardPoints
            };

            const response = await axios.post(API_URL + '/block-seats', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });

            const updatedBookSummary = {
                ...bookSummary,
                confirmationCode: response.data.transactionPNR,
                ticketCode: response.data.ticketCode,
                orderId: response.data.orderId,
                orderStatus: response.data.orderStatus,
                totalCost: convertToRupees(response.data.totalCost),
                pointsApplied: response.data.pointsApplied
            };
            setBookSummary(updatedBookSummary);
            handleBookSummaryChange(updatedBookSummary);

            handlePayment(updatedBookSummary);
        } catch (error) {
            console.error(error);
            setErrorMessage('Failed to confirm seat. Please try again');
            setShowErrorMessage(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSuccess = useCallback(async (response, currentBookSummary) => {
        const paymentId = response.razorpay_payment_id;
        setLoading(true);
        try {
            const formattedDate = format(currentBookSummary.travelDate, 'yyyy-MM-dd');
            const requestBody = {
                transactionId: paymentId,
                transactionPNR: currentBookSummary.confirmationCode,
                ticketCode: currentBookSummary.ticketCode,
                contactNumber: currentBookSummary.contactPhone,
                contactEmail: currentBookSummary.contactEmail,
                tripIdentifier: currentBookSummary.busAvailability.tripIdentifier,
                origin: currentBookSummary.origin,
                destination: currentBookSummary.destination,
                travelDate: formattedDate,
                boardingPoint: currentBookSummary.boardingPoint,
                droppingPoint: currentBookSummary.dropOffPoint,
                seats: currentBookSummary.seats,
                passengersInfo: currentBookSummary.passengers,
                busAvailability: currentBookSummary.busAvailability,
                userId: user ? user.userId : '',
                promoCode : bookSummary.validatedPromo ? bookSummary.validatedPromo.offerCode : null,
                useRewardPoints: bookSummary.useRewardPoints,
                pointsApplied: bookSummary.pointsApplied,
                pos: {
                    type: 'WEB'
                }
            };

            const response = await axios.post(API_URL + '/confirm-seats', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });

            const updatedBookSummary = {
                ...currentBookSummary,
                confirmationCode: response.data.confirmationCode,
                operatorPNR: response.data.operatorPNR,
                paymentId: paymentId
            };
            setBookSummary(updatedBookSummary);
            handleBookSummaryChange(updatedBookSummary);

            handlePaymentSuccess(response);
        } catch (error) {
            console.error('Error:', error);
            setShowErrorMessage(true);
            setErrorMessage('Failed to save confirmation. Please contact our customer support for refund, if payment was processed');
        } finally {
            setLoading(false);
        }
    }, [user, handleBookSummaryChange, handlePaymentSuccess]);

    const handlePayment = useCallback((currentBookSummary) => {
        const options = {
            key: Constants.expoConfig.extra.razorPayKey,
            amount: convertToPaise(currentBookSummary.totalCost),
            currency: 'INR',
            name: 'BYGBUS',
            description: 'Ticket Booking',
            image: 'https://bygbus.com/static/media/bygbus-high-resolution-logo-cropped.a987a185c40e2809bc64.png',
            order_id: currentBookSummary.orderId,
            handler: (response) => handleSuccess(response, currentBookSummary),
            prefill: {
                email: currentBookSummary.contactEmail,
                contact: currentBookSummary.contactPhone
            },
            notes: {
                address: 'BYGBUS Office'
            },
            theme: {
                color: '#5EBC67'
            },
            timeout: 480
        };

        if (Platform.OS === 'web') {
            if (window.Razorpay) {
                const rzp1 = new window.Razorpay(options);
                rzp1.on('payment.failed', function (response) {
                    Alert.alert('Payment Failed', response.error.description);
                });
                rzp1.open();
            } else {
                Alert.alert('Error', 'Razorpay SDK not loaded');
            }
        } else {
            Alert.alert('Web only feature', 'Payment gateway is available only on web.');
        }
    }, [handleSuccess]);

    const renderCostBreakdown = () => {
        const { totalDiscount, hasDiscounts } = calculateTotalDiscounts();
        let subTotal = 0;
        let totalTax = 0;

        const renderPointsSection = () => {
            if (!bookSummary.useRewardPoints || !user?.userId) return null;

            const pointsDiscount = userPoints || 0;
            const pointsValue = pointsDiscount / 100;

            return (
                <View style={styles.pointsContainer}>
                    <Text style={styles.pointsText}>
                        Reward Points Applied: {pointsDiscount} points
                    </Text>
                    <Text style={styles.pointsValue}>
                        Points Value: -₹{pointsValue.toFixed(2)}
                    </Text>
                </View>
            );
        };

        // Calculate initial totals
        bookSummary.seats.forEach(seat => {
            const basePrice = seat.seatRate.baseFare;
            const tax = seat.seatRate.totalTax;
            subTotal += basePrice;
            totalTax += tax;
        });

        // Calculate final amount after all discounts and points
        const promoDiscount = bookSummary.validatedPromo ? 
            (bookSummary.validatedPromo.discountDetails.discountAmount || 0) : 0;
            
        const pointsValue = bookSummary.useRewardPoints ? 
            (userPoints / 100) : 0;

        const finalTotal = (subTotal - totalDiscount - promoDiscount + totalTax - pointsValue).toFixed(2);

        return (
            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Cost Breakdown</Text>
                
                {bookSummary.seats.map((seat, index) => {
                    const basePrice = seat.seatRate.baseFare;
                    const discountedPrice = seat.seatRate.discountedBaseFare || basePrice;
                    const seatDiscount = basePrice - discountedPrice;
                    const tax = seat.seatRate.totalTax;

                    return (
                        <View key={index} style={styles.costDetailContainer}>
                            <Text style={styles.seatLabel}>Seat {seat.seatNo}</Text>
                            <View style={styles.costBreakdown}>
                                <Text style={styles.costItem}>Base Fare: ₹{basePrice.toFixed(2)}</Text>
                                {seatDiscount > 0 && (
                                    <Text style={styles.discountText}>
                                        Discount: -₹{seatDiscount.toFixed(2)}
                                    </Text>
                                )}
                                <Text style={styles.costItem}>Tax: ₹{tax.toFixed(2)}</Text>
                                <Text style={styles.seatTotalText}>
                                    Seat Total: ₹{seat.seatRate.totalCost.toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    );
                })}

                <View style={styles.totalSummaryContainer}>
                    <View style={styles.summaryRow}>
                        <Text style={styles.summaryLabel}>Subtotal:</Text>
                        <Text style={styles.summaryValue}>₹{subTotal.toFixed(2)}</Text>
                    </View>

                    {hasDiscounts && (
                        <View style={styles.summaryRow}>
                            <Text style={[styles.summaryLabel, styles.discountLabel]}>
                                Total Discounts:
                            </Text>
                            <Text style={styles.discountValue}>
                                -₹{totalDiscount.toFixed(2)}
                            </Text>
                        </View>
                    )}

                    <View style={styles.summaryRow}>
                        <Text style={styles.summaryLabel}>Total Taxes:</Text>
                        <Text style={styles.summaryValue}>₹{totalTax.toFixed(2)}</Text>
                    </View>

                    {bookSummary.validatedPromo && (
                        <View style={styles.promoContainer}>
                            <Text style={styles.promoText}>
                                Promo Applied: {bookSummary.validatedPromo.offerCode}
                            </Text>
                            <Text style={styles.promoDiscount}>
                                Promo Discount: -₹{(totalDiscount || 0).toFixed(2)}
                            </Text>
                        </View>
                    )}

                    {renderPointsSection()}

                    <View style={styles.grandTotalContainer}>
                        <Text style={styles.grandTotalLabel}>Grand Total:</Text>
                        <Text style={styles.grandTotalValue}>
                            ₹{finalTotal}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    const renderPolicyLinks = () => (
        <View style={styles.policyContainer}>
            <TouchableOpacity onPress={openTermsModal}>
                <Text style={styles.policyLink}>Terms and Conditions</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={openPrivacyModal}>
                <Text style={styles.policyLink}>Privacy Policy</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={openCancellationRefundPolicyModal}>
                <Text style={styles.policyLink}>Cancellation Refund Policy</Text>
            </TouchableOpacity>
        </View>
    );

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.reviewContainer}>
                <Text style={styles.headerText}>Booking Review</Text>
                {showErrorMessage && (
                    <View style={styles.errorContainer}>
                        <Text style={styles.errorText}>{errorMessage}</Text>
                    </View>
                )}

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Travel/Operator</Text>
                    <Text style={styles.detailText}>From: {bookSummary.origin}</Text>
                    <Text style={styles.detailText}>To: {bookSummary.destination}</Text>
                    <Text style={styles.detailText}>Date: {bookSummary.travelDate.toDateString()}</Text>
                    <Text style={styles.detailText}>
                        Operator: {bookSummary.busAvailability.operator} ({bookSummary.busAvailability.bus.name})
                    </Text>
                    <Text style={styles.detailText}>
                        Departure: {formatTime(bookSummary.busAvailability.departureTime)}
                    </Text>
                    <Text style={styles.detailText}>
                        Arrival: {formatTime(bookSummary.busAvailability.arrivalTime)}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Contact Details</Text>
                    <Text style={styles.detailText}>Email: {bookSummary.contactEmail}</Text>
                    <Text style={styles.detailText}>Phone: {bookSummary.contactPhone}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Passenger Details</Text>
                    <View style={styles.passengersContainer}>
                        {bookSummary.passengers.map((passenger, index) => (
                            <View key={index} style={styles.passengerContainer}>
                                <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                <Text style={styles.detailText}>
                                    Name: {passenger.title} {passenger.firstName} {passenger.lastName}
                                </Text>
                                <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                            </View>
                        ))}
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Boarding Point</Text>
                    <Text style={styles.detailText}>Point Name: {bookSummary.boardingPoint.pointName}</Text>
                    <Text style={styles.detailText}>Landmark: {bookSummary.boardingPoint.landMark}</Text>
                    <Text style={styles.detailText}>
                        Arrival Time: {formatTime(bookSummary.boardingPoint.arrivalTime)}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Drop-Off Point</Text>
                    <Text style={styles.detailText}>Point Name: {bookSummary.dropOffPoint.pointName}</Text>
                    <Text style={styles.detailText}>Landmark: {bookSummary.dropOffPoint.landMark}</Text>
                    <Text style={styles.detailText}>
                        Arrival Time: {formatTime(bookSummary.dropOffPoint.arrivalTime)}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                    {bookSummary.busAvailability.cancellationPolicy && 
                     bookSummary.busAvailability.cancellationPolicy.cancellationPenalty.map((penalty, index) => (
                        <View key={index} style={styles.penaltyContainer}>
                            <Text style={styles.detailText}>
                                Between {penalty.from ? convertTo12HourFormat(penalty.from) : 'Departure'} 
                                and {penalty.to ? convertTo12HourFormat(penalty.to) : 'Booked Date/time'}:
                                {penalty.refundable ? 
                                    ` ${penalty.chargeType === 'AMOUNT' ? '₹' : ''}${penalty.cancellationCharge}${penalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged` :
                                    ' Non Refundable'}
                            </Text>
                        </View>
                    ))}
                </View>

                {renderCostBreakdown()}

                <View style={styles.section}>
                    <View style={styles.reviewCheckBox}>
                        <CheckBox value={isChecked} onValueChange={setIsChecked} />
                        <Text style={styles.termsText}>I agree to the</Text>
                    </View>
                    {renderPolicyLinks()}
                </View>

                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.editButton} onPress={onEdit}>
                        <Text style={styles.buttonText}>Edit Booking</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.confirmButton, !isChecked && styles.disabledButton]}
                        onPress={handleSeatBlock}
                        disabled={!isChecked || loading}
                    >
                        {loading ? (
                            <ActivityIndicator size="small" color="#fff" />
                        ) : (
                            <Text style={styles.buttonText}>Pay ₹{bookSummary.totalCost}</Text>
                        )}
                    </TouchableOpacity>
                </View>
            </View>

            <TermsAndConditionsModal 
                isVisible={termsModalVisible} 
                closeModal={closeTermsModal} 
            />
            <PrivacyPolicyModal 
                isVisible={privacyModalVisible} 
                closeModal={closePrivacyModal} 
            />
            <CancellationRefundPolicyModal 
                isVisible={CancellationRefundPolicyModalVisible} 
                closeModal={closeCancellationRefundPolicyModal} 
            />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 20,
        backgroundColor: '#f5f5f5',
    },
    reviewContainer: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 3,
    },
    headerText: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
        color: '#333',
    },
    section: {
        marginBottom: 20,
        padding: 15,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 15,
        color: '#333',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        paddingBottom: 8,
    },
    detailText: {
        fontSize: 16,
        marginBottom: 8,
        color: '#444',
    },
    costDetailContainer: {
        marginBottom: 15,
        padding: 10,
        backgroundColor: '#f8f9fa',
        borderRadius: 6,
    },
    seatLabel: {
        fontSize: 16,
        fontWeight: '600',
        marginBottom: 8,
        color: '#333',
    },
    costBreakdown: {
        marginLeft: 15,
    },
    costItem: {
        fontSize: 14,
        color: '#666',
        marginBottom: 4,
    },
    discountText: {
        fontSize: 14,
        color: '#4CAF50',
        marginBottom: 4,
    },
    seatTotalText: {
        fontSize: 15,
        fontWeight: '600',
        color: '#333',
        marginTop: 4,
    },
    totalSummaryContainer: {
        marginTop: 20,
        padding: 15,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    summaryLabel: {
        fontSize: 15,
        color: '#666',
    },
    summaryValue: {
        fontSize: 15,
        fontWeight: '500',
        color: '#333',
    },
    discountLabel: {
        color: '#4CAF50',
    },
    discountValue: {
        color: '#4CAF50',
        fontWeight: '500',
    },
    promoContainer: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#e8f5e9',
        borderRadius: 6,
    },
    promoText: {
        fontSize: 14,
        color: '#2e7d32',
        marginBottom: 4,
    },
    promoDiscount: {
        fontSize: 15,
        fontWeight: '600',
        color: '#2e7d32',
    },
    pointsContainer: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#e3f2fd',
        borderRadius: 6,
    },
    pointsText: {
        fontSize: 14,
        color: '#1565c0',
        marginBottom: 4,
    },
    pointsValue: {
        fontSize: 15,
        fontWeight: '600',
        color: '#1565c0',
    },
    grandTotalContainer: {
        marginTop: 15,
        paddingTop: 15,
        borderTopWidth: 1,
        borderTopColor: '#ddd',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    grandTotalLabel: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    grandTotalValue: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    passengersContainer: {
        marginTop: 10,
    },
    passengerContainer: {
        marginBottom: 15,
        padding: 12,
        backgroundColor: '#f8f9fa',
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#eee',
    },
    passengerHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
        color: '#333',
    },
    penaltyContainer: {
        marginBottom: 8,
    },
    reviewCheckBox: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    termsText: {
        marginLeft: 8,
        fontSize: 16,
        color: '#444',
    },
    policyContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
    },
    policyLink: {
        color: '#0066cc',
        textDecorationLine: 'underline',
        fontSize: 14,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    editButton: {
        backgroundColor: '#ff9800',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 6,
        minWidth: 120,
        elevation: 2,
    },
    confirmButton: {
        backgroundColor: '#4CAF50',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 6,
        minWidth: 120,
        elevation: 2,
    },
    disabledButton: {
        backgroundColor: '#ccc',
        elevation: 0,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '600',
        textAlign: 'center',
    },
    errorContainer: {
        backgroundColor: '#ffebee',
        padding: 12,
        borderRadius: 6,
        marginBottom: 20,
    },
    errorText: {
        color: '#c62828',
        fontSize: 14,
        textAlign: 'center',
    }
});

export default BookBookingReview;