import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

const BusTripSummary = ({ bookSummary, onModify }) => {

    return (
        <View style={styles.container}>
            <View style={styles.summaryContainer}>
                {/* Display Summary Container */}
                <Text style={styles.summaryDetails}>{bookSummary.origin}</Text>
                <Text style={styles.summaryDetails}>---</Text>
                <MaterialCommunityIcons name="bus-side" size={24} color="#5EBC67" />
                <Text style={styles.summaryDetails}>---</Text>
                <Text style={styles.summaryDetails}>{bookSummary.destination}</Text>
                <Text style={styles.summaryDetails}>On</Text>
                <Text style={styles.summaryDetails}>{bookSummary.travelDate.toDateString()}</Text>

                {/* Modify Button */}
                <TouchableOpacity onPress={onModify} style={styles.modifyButton}>
                    <Text style={styles.modifyButtonText}>Modify</Text>
                </TouchableOpacity>
            </View>
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        marginVertical: 10
    },
    summaryContainer: {
        backgroundColor: '#f2f2f2',
        borderRadius: 5,
        padding: 10,
        width: '90%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    modifyButton: {
        backgroundColor: '#5EBC67',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        marginHorizontal: 5,
    },
    modifyButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
       // fontFamily: 'Aldrich'
    },
    summaryDetails: {
        marginHorizontal: 10,
        fontSize: 16,
       // fontFamily: 'Aldrich'
    }
});

export default BusTripSummary;