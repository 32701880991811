import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform, Linking } from 'react-native';

const AppleIcon = () => (
  <svg width="16" height="16" viewBox="0 0 384 512" fill="currentColor">
    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
  </svg>
);

const AndroidIcon = () => (
  <svg width="16" height="16" viewBox="0 0 576 512" fill="currentColor">
    <path d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"/>
  </svg>
);

const MobileAppBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const detectMobile = () => {
      if (Platform.OS === 'web') {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          setPlatform('ios');
          setShowBanner(true);
          return;
        }
        
        if (/android/i.test(userAgent)) {
          setPlatform('android');
          setShowBanner(true);
          return;
        }
      }
    };

    detectMobile();
  }, []);

  const handleDownload = () => {
    const appLinks = {
      ios: 'https://testflight.apple.com/join/mfxbGfUD',
      android: 'https://play.google.com/store/apps/details?id=com.bygbus.mobile'
    };
    
    const link = platform === 'ios' ? appLinks.ios : appLinks.android;
    if (Platform.OS === 'web') {
      window.open(link, '_blank');
    } else {
      Linking.openURL(link);
    }
  };

  if (!showBanner || !platform) return null;

  const PlatformIcon = platform === 'ios' ? AppleIcon : AndroidIcon;

  return (
    <View style={styles.banner}>
      <View style={styles.content}>
        <View style={styles.textContainer}>
          <Text style={styles.betaTag}>BETA VERSION</Text>
          <Text style={styles.title}>
            <Text style={styles.iconWrapper}><PlatformIcon /></Text>
            Download BygBus App
          </Text>
          <Text style={styles.description}>
            Experience our new {platform === 'ios' ? 'iOS' : 'Android'} app
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <TouchableOpacity 
            style={[
              styles.downloadButton,
              platform === 'ios' ? styles.iosButton : styles.androidButton
            ]} 
            onPress={handleDownload}
          >
            <Text style={styles.iconWrapper}><PlatformIcon /></Text>
            <Text style={styles.buttonText}>Download Beta</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.closeButton} onPress={() => setShowBanner(false)}>
            <Text style={styles.closeText}>✕</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  banner: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#EBF5FF',
    borderTopWidth: 1,
    borderTopColor: '#BEE3F8',
    padding: 16,
    zIndex: 1000,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  textContainer: {
    flex: 1,
    marginRight: 16,
    minWidth: 200,
  },
  betaTag: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#E53E3E',
    marginBottom: 4,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2C5282',
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    fontSize: 14,
    color: '#4A5568',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  downloadButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 6,
    marginRight: 8,
  },
  iosButton: {
    backgroundColor: '#000000',
  },
  androidButton: {
    backgroundColor: '#3DDC84',
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '600',
    marginLeft: 8,
  },
  closeButton: {
    padding: 8,
  },
  closeText: {
    fontSize: 18,
    color: '#4A5568',
  },
  iconWrapper: {
    marginRight: 6,
    color: '#FFFFFF',
  },
});

export default MobileAppBanner;